<template>
  <div class="forgot-password" :class="{ 'has-notify': !!getAuthNotification }">
    <AuthNotification v-if="!!getAuthNotification" />
    <div class="container">
      <router-view />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import AuthNotification from '@/elements/AuthNotification.vue';

export default {
  components: {
    AuthNotification,
  },
  computed: {
    ...mapGetters(['getAuthNotification']),
  },
};
</script>

<style lang="sass" scoped>
.forgot-password
  width: 100%
  padding-top: 120px
  @include l
    padding-top: 180px
  @include ms
    padding-top: 0
</style>
